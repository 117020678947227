import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Impressum from "../components/footer.js";
import favicon from '../assets/favicon.ico';
import gebäude from "../images/gebaeude.png"
import "../css/ueberuns.css";

const UeberunsPage = () => {
  return (
    <div>
       <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="noindex" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />
      <body>
        <section className="presentation">
          <div className="registerueberuns">
            <div className="text">
              <h1>Über uns</h1>
              <p>Im Jahr 1998 ist das Pneuhaus Rogenmoser mit dem Sitz in Auw gegründet worden. Das Familienunternehmen ist in den letzten Jahren stets gewachsen. Wir dürfen bis heute einen konstanten, zufriedenen Kundenstamm betreuen. Von Montag bis Samstag sind wir gerne Ihr Ansprechspartner bei Anliegen rund um den Pneuservice. </p>
            </div>
          </div>
          <img className="pictureueberuns" src={gebäude} alt="Gebäude Pneuhaus Rogenmoser" />
        </section>
      </body>
      <Impressum/>
    </div>

  );
};

export default UeberunsPage;